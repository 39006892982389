import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import YouTubeGrid from 'react-youtube-grid'
import { Link } from "react-router-dom";


function ProductPage() {
    const photos = [
        {
            src: "./work/product/1.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/product/2.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/product/3.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/product/w-yn3-shih-.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/product/w-yn3-shih-untitledps.jpg",
            width: 4,
            height: 3
        },
       
       

    ];

    const Bannerdata = { title: "產品動畫", img: "w-yn3-shih-56866432-308258056516058-1086070699421859840-n.jpg" }


    return (
        <div >
            <Banner data={Bannerdata} />
            <div className="m-10">
            < YouTubeGrid  youtubeUrls = { [ "SPH6AzV22e4", "RdDZRM8xmsA","SyfcdVjucAE"] }  / >
            </div>

            <div className="m-10">
                <Gallery photos={photos} />
 
            </div>
            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div>
    )
}

export default ProductPage