import React from 'react'
import Home from './component/Home'
import Navbar from './component/NavBar'
import Footer from './component/Footer'
import Contact from './component/Contact'


import Work from './component/Work'
import InteriorPage from './component/work/InteriorPage'
import ArchiPage from './component/work/ArchiPage'


import { Routes, Route ,Navigate} from "react-router-dom"
import VFXPage from './component/work/VFXPage'
import UEPage from './component/work/UEPage'
import ProductPage from './component/work/ProductPage'
import FDMPage from './component/work/FDMPage'
import ConceptPage from './component/work/ConceptPage'

import ScrollToTop from './ScrollToTop'







export default function App() {
  return (
    <div>
      <Navbar />
      <ScrollToTop />
      <Routes >

        <Route path="/" element={<Home />} />

        <Route path="/work" element={<Work />} />
        



        <Route path="/contact" element={<Contact />} />
        <Route path="/interior" element={<InteriorPage />} />
        <Route path="/arch" element={<ArchiPage />} />
        <Route path="/vfx" element={<VFXPage />} />
        <Route path="/ue" element={<UEPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/3d" element={<FDMPage />} />
        <Route path="/concept" element={<ConceptPage />} />
        

      


        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />

      </Routes>

      <Footer />
    </div>

  )
}

