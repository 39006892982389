import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import { Link } from "react-router-dom";




function ArchiPage() {
    const photos = [
        {
            src: "/work/Archi/archi1.png",
            width: 4,
            height: 3
        },
        {
            src: "/work/Archi/archi2.png",
            width: 1,
            height: 1
        },

    ];

    const Bannerdata = { title: "Archi", img: "w-yn3-shih-night.jpg" }


    return (
        <div >
            <Banner data={Bannerdata} />

            <div className="m-10">
                <Gallery photos={photos} />

            </div>
            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div>
    )
}

export default ArchiPage