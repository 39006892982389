import React from 'react'
import { Link } from "react-router-dom";



function Home() {


    return (
        <div>
            <header className="relative flex items-center justify-center h-[30rem] mb-12 overflow-hidden">
                <div className=" relative z-30 p-5 text-2xl flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-30">
                    <div className="text-center divide-y-2">
                        <h1 className="font-mono text-6xl font-bold text-white uppercase lg:text-6xl p-5 ">WAYNE SHIH</h1>

                        <h3 className="font-mono text-6xl font-bold text-white uppercase lg:text-6xl p-5">3D Generalist</h3>
                    </div>
                </div>
                <video autoPlay loop muted className=" absolute z-10 w-auto min-w-full min-h-full max-w-none">
                    <source src="home.mp4" type="video/mp4" />Your browser does not support the video tag.
                </video>
            </header>
            <div>
                <div className="mb-10 rounded-xl bg-white shadow-xl md:mx-auto lg:w-9/12 xl:w-7/12 flex items-center justify-center">
                    <div className="grid md:grid-cols-2  ">
                        <img src="me.jpg" className="rounded-t-xl h-full w-full object-cover md:rounded-l-xl md:rounded-tr-none " alt="image" width="640" height="422" loading="lazy" />
                        <div className="mx-auto space-y-6 p-6  sm:p-8">
                            <div className="w-200 mx-auto">
                                <img src="Wayne-Shih.png" alt="Wayne-Shih" height="1000" width="200" loading="lazy" />
                            </div>



                            <ul className='list-disc list-inside'>
                                <li>建築室內外、景觀 渲染圖 / 動畫製作</li>
                                <li>建築空間設計 / 建築資訊模型</li>
                                <li>Unreal Engine 場景、建築室內外渲染</li>
                                <li>3D列印</li>
                                <li>網頁前後端</li>
                            </ul>
                            <h6 className="text-lg font-semibold leading-none">施又瑋 | Wayne-Shih</h6>
                            <Link to="/work">
                                <button className="w-auto px-3 py-5  mt-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">作品集</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Home