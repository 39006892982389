import React from 'react'

const Banner = ({ data }) => {
    return (

        <div className=" relative   overflow-hidden bg-white">
            <div className='bg-gray-100 h-60  relative'>
                <img
                    src={data.img}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full "
                />
                <h1 className=" absolute font-mono text-6xl mr top-10 left-0 px-6 py-12 font-bold inset-x-0 text-white uppercase lg:text-6xl  text-center leading-4">{data.title}</h1>
            </div>
        </div>
    )
}

export default Banner