import React from 'react'


function Contact() {

    return (
        

        <div class="flex flex-col h-screen bg-center bg-cover bg-no-repeat">
              <div class="grid place-items-center mx-auto p-20 sm:my-auto bg-white rounded-3xl space-y-10">
                    <h1 class="text-5xl font-semibold text-gray-500">CONTACT</h1>
                    
                    <div class="flex items-center justify-center space-x-3">

                        <a href="https://www.facebook.com/event1329211/" title="facebook" target="blank" aria-label="facebook">
                            <button class="bg-blue-500 px-4 py-2 font-semibold text-white inline-flex items-center  rounded">
                                <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg>
                                <span></span>
                            </button>
                        </a>

                        <a href="https://www.linkedin.com/in/yo-wei-shih-0579b6142/" title="linkedin" target="blank" aria-label="linkedin">
                            <button class="bg-blue-300 px-4 py-2 font-semibold text-white inline-flex items-center  rounded">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 m-auto" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                                </svg>
                                <span></span>
                            </button>
                        </a>
                        <a href="https://www.youtube.com/channel/UCelY0vnctWY2UvFA4WHINIA" title="Youtube" target="blank" aria-label="Youtube">
                            <button class="bg-red-500 px-4 py-2 font-semibold text-white inline-flex items-center  rounded">
                                <svg xmlns="http://www.w3.org/2000/svg"  className="w-5 m-auto" viewBox="0 0 24 24" fill="#ffffff"><path d="M12.04 3.5c.59 0 7.54.02 9.34.5a3.02 3.02 0 0 1 2.12 2.15C24 8.05 24 12 24 12v.04c0 .43-.03 4.03-.5 5.8A3.02 3.02 0 0 1 21.38 20c-1.76.48-8.45.5-9.3.51h-.17c-.85 0-7.54-.03-9.29-.5A3.02 3.02 0 0 1 .5 17.84c-.42-1.61-.49-4.7-.5-5.6v-.5c.01-.9.08-3.99.5-5.6a3.02 3.02 0 0 1 2.12-2.14c1.8-.49 8.75-.51 9.34-.51zM9.54 8.4v7.18L15.82 12 9.54 8.41z" /></svg>
                                <span></span>

                            </button>
                        </a>


                    <a href="mailto:event1329211@gmail.com" aria-label="send mail">
                        <button class="bg-gray-500 px-4 py-2 font-semibold text-white inline-flex items-center  rounded">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 m-auto" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                                
                            </svg>
                            <span></span>
                        </button>
                    </a>

                </div>
            </div>
        </div>
        


    )
}

export default Contact